<template>
  <div class="my-16">
    <div class="my-10">
      <div>
        <div>
          <image-input
            :modelinput="testInputData"
            v-on:on-passcode-submit="callemailApi($event)"
            :isLoading="isLoading"
            :boxes="CoordinareBoxes"
          ></image-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from "./ImageInput.vue";
export default {
  components: {
    ImageInput
  },
  data() {
    return {
      isSubmitted: false
    };
  },
  methods: {
    callemailApi(imglink) {
      this.$emit("email-given", imglink);
    }
  },

  props: {
    testInputData: Object,
    result: Array,
    isLoading: Boolean,
    requestMade: Array,
    CoordinareBoxes: Array
  }
};
</script>

<style>
</style>