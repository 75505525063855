<template>
  <div>
    <div class="input-image-section m-auto relative border-2" @dragover.prevent @drop="onChange">
      <img
        data-aos="zoom-in"
        data-aos-delay="200"
        data-aos-once="true"
        class="article-item__image"
        :src="inputURL ? inputURL : imgURL "
      />
      <div v-if="boxes && !isChanged">
        <div
          v-for="(item,index) of boxes"
          :key="index"
          class="absolute z-2 border-2 border-red-500"
          :style="{ left: item.box[0] + '%', top: item.box[1] + '%', width: item.box[2] + '%', height: item.box[3] + '%'}"
          :class="boxes? 'd-block' : ''"
        >
          <!-- age gender bounding box component  -->
          <age-gender-bounding-box
            v-if="item.gender"
            :id="index"
            :age="item.age"
            :gender="item.gender"
            v-on:hovered="onBlinkHover($event)"
          />

          <!-- Face spoofing bounding box component  -->
          <face-spoofing-bounding-box
            v-if="item.is_spoofed !=undefined && boxes"
            :id="index"
            :value="item.is_spoofed? 'Spoofed' : 'Not Spoofed'"
            v-on:hovered="onBlinkHover($event)"
          />

          <!-- Object Detection bounding box -->
          <object-detection-bounding-box
            v-if="currentRouteName == '/object-detection' && boxes"
            :id="index"
            :name="item.name"
            :confidence="item.confidence"
            v-on:hovered="onBlinkHover($event)"
          />

          <!-- Celebrity bounding box - used object detect since it take same values -->
          <celebrity-recognition-bounding-box
            v-if="currentRouteName == '/celebrity-recognition' && boxes"
            :id="index"
            :name="item.name"
            v-on:hovered="onBlinkHover($event)"
          />

          <!-- Emotion Detection bounding box -->
          <div v-if="currentRouteName == '/emotion-detection' && boxes">
            <emotion-detection-bounding-box
              :id="index"
              v-on:hovered="emotionDetecionValues($event)"
              :disgust="item.disgust"
              :angry="item.angry"
              :scared="item.scared"
              :happy="item.happy"
              :sad="item.sad"
              :surprised="item.surprised"
              :neutral="item.neutral"
            />
          </div>

          <!-- Weapon detection bounding box -->
          <object-detection-bounding-box
            v-if="currentRouteName == '/weapon-detection' && boxes"
            :id="index"
            :name="item.name"
            :confidence="item.confidence"
            v-on:hovered="onBlinkHover($event)"
          />

          <!-- Wheelchair detection bounding box -->
          <object-detection-bounding-box
            v-if="currentRouteName == '/wheelchair-detection' && boxes"
            :id="index"
            :name="item.name"
            :confidence="item.confidence"
            v-on:hovered="onBlinkHover($event)"
          />
        </div>
      </div>
    </div>
    <div class="sample-img-section mt-5">
      <div class="pr-3">
        <div class="text-small py-2">Choose a Sample image</div>

        <div class="flex flex-col sm:flex-row">
          <div class="flex pr-3">
            <div
              class="imageone"
              :class="{ 'border-2 border-black p-1': imageOneSelected }"
              @click="[imageTwoSelected=false,imageOneSelected=true]"
            >
              <!-- <img src="https://pbs.twimg.com/profile_images/669103856106668033/UF3cgUk4_400x400.jpg" alt="" @click="GetSource($event)"> -->
              <img
                data-aos="zoom-in"
                data-aos-delay="200"
                data-aos-once="true"
                :src="modelinput.sampleImageOne"
                alt
                @click="GetSource($event)"
                class="object-cover"
              />
            </div>
            <div
              class="imagetwo ml-3"
              :class="{ 'border-2 border-black p-1': imageTwoSelected }"
              @click="[imageTwoSelected=true,imageOneSelected=false]"
            >
              <img
                data-aos="zoom-in"
                data-aos-delay="200"
                data-aos-once="true"
                :src="modelinput.sampleImageTwo"
                alt
                @click="GetSource($event)"
                class="object-cover"
              />
            </div>
          </div>
          <!--  -->
          <div class="text-small relative pl-3 sm:border-l d-none" v-if="modelinput.isLocalInput">
            <div class="text-sm">
              <p>
                Use your own image
                <br />
                <span class="text-small">image must be .jpeg or png formant</span>
              </p>
            </div>

            <label v-if="!uploadImg" class="mb-0 btn btn-dark display-inline">
              SELECT OR DROP AN IMAGE in Image Area
              <input
                type="file"
                name="image"
                @change="onChange"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="inputURLSection sm:pl-3 w-100 border-t mt-2 relative">
        <form @submit.prevent="onUrlSubmit()" class="mt-3">
          <label for="urlInput">URL</label>
          <div class="input-group mb-3 text-small">
            <input
              data-aos="zoom-in"
              data-aos-delay="200"
              data-aos-once="true"
              ref="fileInput"
              v-model="inputURL"
              type="text"
              class="form-control"
              placeholder="Please input img URL"
              aria-label="imageurl"
            />
          </div>
          <base-button
            :isConfirm="true"
            class="btn btn-primary mx-auto"
            :state="submitButtonState"
          >Submit</base-button>
        </form>
        <email-input-modal
          class="absolute"
          v-bind:is-visible.sync="showEmailInputModal"
          v-on:valid-email="onEmailSubmit($event)"
          v-on:close="showEmailInputModal=false"
        />

        <passcode-input-modal
          class="absolute"
          v-bind:is-visible.sync="showPasscodeModal"
          v-on:valid-passcode="onPassCodeSubmit()"
          :email="emailAddress"
          v-on:close="showPasscodeModal=false"
          v-on:invalid-passcode="showPasscodeModal=false"
        />
        <!-- v-on:close="showPasscodeModal=false" -->
      </div>
    </div>
  </div>
</template>

<script>
import S3 from "aws-s3";

import BaseButton from "../BaseButton.vue";
import ButtonState from "../../lib/button-state";
import EmailInputModal from "../Products/EmailInputModal.vue";
import PasscodeInputModal from "./PasscodeInputModal.vue";
import SessionService from "../../services/SessionService";
import AgeGenderBoundingBox from "./AgeGender/AgeGenderBoundingBox.vue";
import FaceSpoofingBoundingBox from "./FaceSpoofing/FaceSpoofingBoundingBox.vue";
import ObjectDetectionBoundingBox from "./ObjectDetection/ObjectDetectionBoundingBox.vue";
import EmotionDetectionBoundingBox from "./EmotionDetection/EmotionDetectionBoundingBox.vue";
import CelebrityRecognitionBoundingBox from "./CelebrityRecognition.vue/CelebrityRecognitionBoundingBox.vue";

export default {
  components: {
    BaseButton,
    EmailInputModal,
    PasscodeInputModal,
    AgeGenderBoundingBox,
    FaceSpoofingBoundingBox,
    ObjectDetectionBoundingBox,
    EmotionDetectionBoundingBox,
    CelebrityRecognitionBoundingBox
  },
  data() {
    return {
      imgURL: this.modelinput.imgURL,
      inputURL: "",
      uploadImg: "",
      files: "",
      isResult: false,
      showEmailInputModal: false,
      showPasscodeModal: false,
      bucketName: "magure-inc-demo-images",
      submitButtonState: ButtonState.Ready,
      emailAddress: null,
      isEmailGiven: Boolean,
      isPasscodeGiven: Boolean,
      isChanged: Boolean,
      imageOneSelected: true,
      imageTwoSelected: false
    };
  },
  methods: {
    onBlinkHover(id) {
      window.$("#info" + id).each(function() {
        window.$(this).popover("show");
        window
          .$(
            window
              .$(this)
              .data("bs.popover")
              .getTipElement()
          )
          .css("height", "auto");
      });
    },

    emotionDetecionValues(id) {
      window.$("#info" + id).each(function() {
        window.$(this).popover("show");
        window
          .$(
            window
              .$(this)
              .data("bs.popover")
              .getTipElement()
          )
          .css("height", "auto");
        window
          .$(
            window
              .$(this)
              .data("bs.popover")
              .getTipElement()
          )
          .css("width", "250px");
      });
    },

    onUrlSubmit() {
      this.isEmailGiven = SessionService.getIsCurrentClientGivenEmail();
      this.isPasscodeGiven = SessionService.getIsPasscodePresent();
      this.imgURL = this.inputURL ? this.inputURL : this.imgURL;
      if (this.isEmailGiven && this.isPasscodeGiven) {
        this.submitButtonState = ButtonState.Loading;
        this.$emit("on-passcode-submit", this.imgURL);
      } else {
        this.showEmailInputModal = true;

        // if(this.files[0]) {
        // let file = this.files[0]

        //     let newFileName = this.files[0].name;
        //     let fileExtension = newFileName.split('.')[1];
        //s3
        // this.S3Client
        // .uploadFile(file, newFileName).finally(()=>{
        //   let fileExtension = newFileName.split('.')[1]
        //   let uploadedURL = `${this.url}.${fileExtension}`
        //   console.log("uploadedURL",uploadedURL);
        // });
        // end of s3
        // }
        // else {
        //     console.log(this.imgURL);
        // }
      }
    },
    onEmailSubmit(event) {
      this.emailAddress = event.email;
      this.showEmailInputModal = false;
      this.$emit("on-email-submit", this.emailAddress);
      this.showPasscodeModal = true;
    },

    onPassCodeSubmit() {
      this.showPasscodeModal = false;
      this.$emit("on-passcode-submit", this.imgURL);
    },

    GetSource(e) {
      this.inputURL = "";
      this.imgURL = e.target.currentSrc;
    },

    onChange(e) {
      e.stopPropagation();
      e.preventDefault();
      this.files = e.target.files ? e.target.files : e.dataTransfer.files;
      this.createFile(this.files[0]);
      this.inputURL = "";
      this.imgURL = this.files[0];
    },

    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      var reader = new FileReader();
      var instace = this;

      reader.onload = function(e) {
        instace.imgURL = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    removeFile() {
      this.imgURL = "";
    }
  },

  mounted() {
    this.onBlinkHover();
  },

  computed: {
    currentRouteName() {
      // console.log(this.$route.path)
      return this.$route.path;
    },
    s3Keys() {
      return "s3_keys";
    },
    config() {
      return {
        bucketName: "",
        // dirName: "thumbnail",
        region: "us-east-2",
        accessKeyId: "",
        secretAccessKey: "",
        s3Url: this.baseURL
      };
    },
    baseURL() {
      return "https://magure-inc-demo-images.s3-us-east-2.amazonaws.com";
    },
    S3Client() {
      return new S3(this.config);
    },
    //   newFileName(){
    //     return Math.random().toString().slice(2)
    //   },
    url() {
      return `${this.baseURL}/${this.bucketName}/${this.newFileName}`;
    }
  },

  watch: {
    isLoading: function(newVal) {
      if (newVal === false) {
        this.submitButtonState = ButtonState.Ready;
        this.isChanged = false;
      }
    },

    inputURL(newVal) {
      if (newVal) {
        this.isChanged = true;
        this.imageOneSelected = false;
        this.imageTwoSelected = false;
      }
      // else {
      //     this.imageOneSelected = true;
      //     this.imgURL = this.modelinput.sampleImageOne;
      // }
    },

    imgURL(newVal) {
      if (newVal) {
        this.isChanged = true;
      }
    }
  },

  props: {
    modelinput: Object,
    isLoading: Boolean,
    boxes: Array,
    hasResult: Array
  }
};
</script>

<style lang="scss" scoped>
::placeholder {
  font-size: 12px;
}
input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
img {
  width: 100%;
  height: 100%;
  // object-fit: cover;
}
.input-image-section {
  height: auto;
  width: auto;
  // max-height: 53vw;

  @media (max-width: 500px) {
    height: 15em;
  }
}
.sample-img-section {
  .imageone,
  .imagetwo {
    height: 6em;
    width: 6em;
    cursor: pointer;
  }
}
// .blinking {
//   -webkit-animation: 2s blink ease infinite;
//   -moz-animation: 2s blink ease infinite;
//   -ms-animation: 2s blink ease infinite;
//   -o-animation: 2s blink ease infinite;
//   animation: 2s blink ease infinite;
//   font-size: 4em;

// }

// @keyframes blink {
//   from, to {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
// }
// .info.popover .popover {
//     height: auto !important;
// }
// .bs-popover-top {
//     height: auto !important;
// }

// @-moz-keyframes blink {
//   from, to {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
// }

// @-webkit-keyframes blink {
//   from, to {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
// }
a:hover {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 500px) {
  .inputURLSection {
    margin-top: 1em;
  }
}
</style>