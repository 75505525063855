<template>
  <div class="absolute" style="left:-20px">
        <a class="info-popover blink" :id="'info'+ Math.floor(id)" tabindex="0" data-toggle="popover" data-container="body"  
        :data-content='inner_html' data-html="true" data-placement="top" data-trigger="hover" @mouseover="emitHoverEvent(id)">
            <svg height="20" width="20" class="blinking">
                <circle cx="10" cy="10" r="10" fill="red" />
                Sorry, your browser does not support inline SVG.  
            </svg> 
        </a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // htmlTemplate: `<div class="progress"><div class="progress-bar" role="progressbar" style="width:` + this.getPercentage()`%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div></div>`
        }
    },
    methods: {
        emitHoverEvent(id) {
            this.$emit('hovered',id)
        },
        getPercentage() {
            return this.percentage;
        }

    },
    computed: {
        inner_html() {
            return `
            <div class="progress"><div class="progress-bar pl-1" role="progressbar" style="color:#000;width: ` + this.angry +`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Angry `+this.angry+`</div></div>
            <div class="progress mt-3"><div class="progress-bar pl-1" role="progressbar" style="color:#000;width: ` + this.disgust +`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Disgust `+this.disgust+`</div></div>
            <div class="progress mt-3"><div class="progress-bar pl-1" role="progressbar" style="color:#000;width: ` + this.scared +`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Scared `+this.scared+`</div></div>
            <div class="progress mt-3"><div class="progress-bar pl-1" role="progressbar" style="color:#000;width: ` + this.happy +`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Happy `+this.happy+`</div></div>
            <div class="progress mt-3"><div class="progress-bar pl-1" role="progressbar" style="color:#000;width: ` + this.sad +`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Sad `+this.sad+`</div></div>
            <div class="progress mt-3"><div class="progress-bar pl-1" role="progressbar" style="color:#000;width: ` + this.surprised +`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Surprised `+this.surprised+`</div></div>
            <div class="progress mt-3"><div class="progress-bar pl-1" role="progressbar" style="color:#000;width: ` + this.neutral +`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Neutral `+this.neutral+`</div></div>
            `; // any raw html
        },
    },

    props: {
        id: Number,
        angry: String,
        disgust: String,
        scared: String,
        happy: String,
        sad: String,
        surprised: String,
        neutral: String,
    }
}
</script>

<style lang="scss" scoped>

</style>