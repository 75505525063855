<template>
  <div class="exclude-navbar CelebrityRecognition-page">
    <container-section>
      <h1
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
        class="text-center"
      >{{emotionDetectionSectionInput.title}}</h1>
      <p
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
        class="pt-3 md:w-4/5 m-auto text-center"
      >{{emotionDetectionSectionInput.description}}</p>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-7" :class="isSubmitted ? '' : 'mx-auto' ">
          <test-section
            :testInputData="emotionDetectionSectionInput"
            v-on:email-given="emotionDetect($event)"
            v-bind:result="responseData"
            :isLoading="isLoading"
            :requestMade="userRequest"
            :CoordinareBoxes="CoordinareBoxes"
          ></test-section>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-5" v-if="!isInvalid">
          <emotion-detection-result
            :result="responseData"
            :isLoading="isLoading"
            :requestMade="userRequest"
            :receivedObjects="capturedEmotions"
          />
        </div>
      </div>
      <!-- try new models section -->
      <div>
        <common-carousel />
      </div>
      <!-- end of try new models section -->
      <!-- Cards section -->
      <div class="row d-none">
        <div class="flex col-6 col-md-4 col-lg-3" v-for="card in applicationCards" :key="card.id">
          <details-card :title="card.title" :description="card.description" :to="card.route" />
        </div>
      </div>
    </container-section>

    <container-section :containerClass="'container-fluid'" :backgroundColor="'#fff'">
      <!-- FAQ's Section -->
      <div class="container mt-10 py-24">
        <h2
          class="my-5"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-once="true"
        >Frequently asked questions</h2>
        <question-drop-down
          v-for="(question,index) of questionsData"
          :key="index"
          :title="question.question"
          :answer="question.answer"
        />
      </div>
    </container-section>

    <div class="about-footer relative">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import TestSection from "../components/AIComponents/TestSection.vue";
import ContainerSection from "../components/ContainerSection.vue";
import SessionService from "../services/SessionService";
import UrlPathService from "../services/UrlPathService";
import MessageService from "../services/MessageService";
import Footer from "../components/Home/Footer.vue";
import DataServies from "../services/DataService";
import EmotionDetectionResult from "../components/AIComponents/EmotionDetectionResult.vue";
import DetailsCard from "../components/Products/DetailsCard.vue";
import QuestionDropDown from "../components/QuestionComponens/QuestionDropDown.vue";
import CommonCarousel from "../components/CommonCarousel.vue";

export default {
  title: "Magure - Emotion Detection",

  components: {
    ContainerSection,
    TestSection,
    Footer,
    EmotionDetectionResult,
    DetailsCard,
    QuestionDropDown,
    CommonCarousel
  },
  data() {
    return {
      applicationCards: DataServies.applicationCardsData,
      CoordinareBoxes: [],
      capturedEmotions: [],
      emotionDetectionSectionInput:
        DataServies.modelStaticData.EmotionDetectionSectionInput,
      isInvalid: Boolean,
      isSubmitted: false,
      isLoading: false,
      responseTime: null,
      responseData: null,
      userRequest: [],
      receivedEmotions: [],
      questionsData: DataServies.AIQuestion
    };
  },

  methods: {
    emotionDetect(img) {
      this.isLoading = true;

      let obj = {
        target_image_url: img,
        email_id: SessionService.getClientEmaiId(),
        passcode: SessionService.getPasscode()
      };
      this.userRequest.shift();
      this.userRequest.push(obj);
      UrlPathService.emotionDetection(obj)
        .then(response => {
          const response_data = response.data;
          const isValid = response_data.response_data;
          if (response_data.response_code == 0) {
            MessageService.show(response_data.description);
            this.isInvalid = true;
          }
          if (isValid.is_vaild === false) {
            SessionService.clearEmailId();
            SessionService.clearPasscode();
          }
          if (response_data.response_code == 1) {
            this.isInvalid = false;
            let data = response.data;
            this.responseData = data.response_data;
            this.responseTime = data.time_taken;
            this.CoordinareBoxes.length = 0;

            this.CoordinareBoxes.length = 0;
            this.capturedEmotions.length = 0;
            for (let obj of this.responseData) {
              if (obj.box) {
                this.objectCount++;
                this.CoordinareBoxes.push(obj);
                this.capturedEmotions.push(obj.name);
              }
            }
          }
          this.isLoading = false;
        })
        .catch(err => {
          let errorMsg = err.message
            ? err.message
            : "Some thing went wrong, Please Try again";
          MessageService.show(errorMsg);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/Variables.scss";
.section-title {
  font-size: 2em;
  padding-bottom: 0.5em;
}

@media (max-width: 500px) {
}
</style>